import React from 'react'
import pr1 from '../../images/event/img-1.jpg'
import pr2 from '../../images/event/img-2.jpg'
import pr3 from '../../images/event/img-3.jpg'
import {Link} from 'react-router-dom'

import './style.css'

const EventSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="wpo-event-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>Our Events</span>
                            <h2>Upcoming Events</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 custom-grid">
                        <div className="wpo-event-item">
                            <div className="wpo-event-img">
                                <img style={{ width: '370px', height: '300px' }} src="https://storage.chicagoariranglionsclub.com/event/4/44th_picnic.svg" alt=""/>
                                <div className="thumb-text">
                                    <span>8</span>
                                    <span>JUN</span>
                                </div>
                            </div>
                            <div className="wpo-event-text">
                                <h2>2024 Korean Cultural Picnic</h2>
                                <ul>
                                    <li><i className="fa fa-clock-o" aria-hidden="true"></i>11:00</li>
                                    <li><i className="fi flaticon-pin"></i>Glenview, IL</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <Link onClick={ClickHandler} to="/event-details">Learn More...</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 custom-grid">
                        <div className="wpo-event-item">
                            <div className="wpo-event-img">
                                <img  style={{ width: '370px', height: '300px' }}  src="https://storage.chicagoariranglionsclub.com/event/3/CALC_KoreaTour_revised.jpg" alt=""/>
                                <div className="thumb-text-2">
                                    <span>25</span>
                                    <span>NOV</span>
                                </div>
                            </div>
                            <div className="wpo-event-text">
                                <h2>Golf Tournament</h2>
                                <ul>
                                    <li><i className="fa fa-clock-o" aria-hidden="true"></i>12:00</li>
                                    <li><i className="fi flaticon-pin"></i>Buffalo Grove, IL</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <Link onClick={ClickHandler} to="/event-details">Learn More...</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 custom-grid">
                        <div className="wpo-event-item">
                            <div className="wpo-event-img">
                                <img style={{ width: '370px', height: '300px' }} src="https://storage.chicagoariranglionsclub.com/event/2/50th_anniversary_4.jpg" alt=""/>
                                <div className="thumb-text-3">
                                    <span>25</span>
                                    <span>NOV</span>
                                </div>
                            </div>
                            <div className="wpo-event-text">
                                <h2>50th Anniversary</h2>
                                <ul>
                                    <li><i className="fa fa-clock-o" aria-hidden="true"></i>18:00</li>
                                    <li><i className="fi flaticon-pin"></i>Arlington Heights, IL</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <Link onClick={ClickHandler} to="/event-details">Learn More...</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventSection;