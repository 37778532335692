// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:30px;
    list-style: none;
    z-index: 99;
}
ul.smothscroll a {
    background-color: rgb(8, 204, 127,.7);
    width: 45px;
    height: 45px;
    line-height: 40px;
    border: 2px solid #00338d;
    border-radius: 0;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}
ul.smothscroll a:hover {
    background-color: #00338d;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/scrollbar/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,qCAAqC;IACrC,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;QACX,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":["ul.smothscroll {\r\n    position: fixed;\r\n    bottom: 25px;\r\n    right:30px;\r\n    list-style: none;\r\n    z-index: 99;\r\n}\r\nul.smothscroll a {\r\n    background-color: rgb(8, 204, 127,.7);\r\n    width: 45px;\r\n    height: 45px;\r\n    line-height: 40px;\r\n    border: 2px solid #00338d;\r\n    border-radius: 0;\r\n    display: block;\r\n    text-align: center;\r\n    color: #fff;\r\n    border-radius: 50%;\r\n}\r\nul.smothscroll a:hover {\r\n    background-color: #00338d;\r\n}\r\n\r\n@media(max-width:767px){\r\n    ul.smothscroll a {\r\n        width: 30px;\r\n        height: 30px;\r\n        line-height: 25px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
