// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-404-section {
  text-align: center;
}

.error-404-section .error-message {
  margin-top: 70px;
  padding: 0 200px;
}

@media (max-width: 991px) {
  .error-404-section .error-message {
      margin-top: 50px;
      padding: 0 100px;
  }
}

@media (max-width: 767px) {
  .error-404-section .error-message {
      padding: 0;
  }
}

.error-404-section .error-message h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 0 0 0.8em;
}

.error-404-section .error-message p {
  margin-bottom: 1.8em;
}

.error-message .theme-btn-s4 {
  background-color: #00338d;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/404/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;MACI,gBAAgB;MAChB,gBAAgB;EACpB;AACF;;AAEA;EACE;MACI,UAAU;EACd;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".error-404-section {\r\n  text-align: center;\r\n}\r\n\r\n.error-404-section .error-message {\r\n  margin-top: 70px;\r\n  padding: 0 200px;\r\n}\r\n\r\n@media (max-width: 991px) {\r\n  .error-404-section .error-message {\r\n      margin-top: 50px;\r\n      padding: 0 100px;\r\n  }\r\n}\r\n\r\n@media (max-width: 767px) {\r\n  .error-404-section .error-message {\r\n      padding: 0;\r\n  }\r\n}\r\n\r\n.error-404-section .error-message h3 {\r\n  font-size: 20px;\r\n  font-size: 1.25rem;\r\n  margin: 0 0 0.8em;\r\n}\r\n\r\n.error-404-section .error-message p {\r\n  margin-bottom: 1.8em;\r\n}\r\n\r\n.error-message .theme-btn-s4 {\r\n  background-color: #00338d;\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
