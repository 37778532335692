import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SidebarWrap from '../SidebarWrap'

import './style.css'

const EventSingle = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
    }

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

        return (
            <div className="wpo-event-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-8">
                            <div className="wpo-event-item">
                                <div className="wpo-event-img">
                                    <img src="https://storage.chicagoariranglionsclub.com/slideshow/picnic2_2400x1600.png" alt=""/>
                                    <div className="thumb-text">
                                        <span>8</span>
                                        <span>JUN</span>
                                    </div>
                                </div>
                                <div className="wpo-event-details-text">
                                    <h2>2024 Korean Cultural Picnic</h2>
                                    <p>Dear Friends:</p>
                                    <p></p>
                                    <p>This year marks the 44th Anniversary of the Chicago Arirang Lions Club annual Picnic for adopted Korean children and their families. As usual, we will have plenty of Korean foods, drinks, games, and entertainment. For your own comfort, we recommend you bring lawn chairs or picnic mats, sunscreen, bug spray and etc. All donationsm comments or suggestions will also be greatly appreciated. We are looking foward to seeing you.</p>
                                </div>
                                <div className="wpo-event-details-wrap">
                                    <div className="wpo-event-details-tab">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggle('1'); }}
                                                >
                                                    Event Schedule
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggle('2'); }}
                                                >
                                                Map Location
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => { toggle('3'); }}
                                                    >
                                                    Contact Us
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <div className="wpo-event-details-content">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1" id="Schedule">
                                                <p>When: June 8th, 2024, Saturday, 11:00 AM - 5:00PM (Rain Or Shine)</p>
                                                <p></p>
                                                <p>Where: Blue Start Memorial Woods, Glenview, IL</p>
                                                <p>On Lake Ave. Between Edens Expressway (I-94) and Waukegan Road (IL-43).</p>
                                                <p>About a mile west from Harms Road, on the south side of the street.</p>
                                                <p></p>
                                                <p>RSVP: by May 18, 2024</p>
                                                <p>1) https://chicagoariranglionsclub.com/events (preffered)</p>
                                                <p>or</p>
                                                <p>2) Mail the attached slip to Chicago Arirang Lions Club at</p>
                                                <p>P.O. Box 506, Glenview, IL 60025</p>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="contact-map enent-map">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2961.2998575068514!2d-87.77880492390511!3d42.07962447122025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc5e0369be8b5%3A0xf96c484d4a2a7678!2z67iU66OoIOyKpO2DgCDrqZTrqqjrpqzslrwg7Jqw7KaI!5e0!3m2!1sko!2sus!4v1722964928348!5m2!1sko!2sus" allowfullscreen></iframe>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <div className="event-contact">
                                                    <div className="wpo-donations-details">
                                                        <form onSubmit={SubmitHandler} id="contact-form">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                                    <input type="text" className="form-control" name="name" id="name" placeholder="Your Name*"/>
                                                                </div>
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix">
                                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"/>
                                                                </div>
                                                                <div className="col-lg-12 col-12 form-group">
                                                                    <textarea className="form-control" name="note" id="note" placeholder="Massage"></textarea>
                                                                </div>
                                                                <div className="submit-area col-lg-12 col-12">
                                                                    <button type="submit" className="theme-btn submit-btn">Submit Now</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SidebarWrap/>

                    </div>
                </div>
            </div>

            );
    }
    
    export default EventSingle;
          
          
          
          
